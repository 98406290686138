$(document).ready(function() {
  'use strict';

  var validator = $('#subscribe-form-nl').validate({
    rules: {
      email: 'required'
    },
    submitHandler: function(form, event) {
      event.preventDefault();
      var $form = $(form);

      $.ajax({
      type: $form.attr('method'),
      url: $form.attr('action'),
      data: $form.serialize(),
      beforeSend: function(data) {
        $form.find(':input').prop('disabled', true);
      },
      success: function(data) {
        if (data.success) {

             $('html, body').animate({
                  scrollTop: $(".completeh2").offset().top
                 }, 1300, 'easeOutCubic');

          document.getElementById("ringraziamenti-newsletter").style.display = "block";
        } else {
          showMessageBox('danger', msg[data.errors.message]);
        }
      },
      failed: function(data) {
        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
      },
      complete: function(data) {
        $('#subscribe-form-nl').find(':input').prop('disabled', false);
        // grecaptcha.reset();
      }
      });
    }
  });



  var validator = $('#subscribe-form-nl-footer').validate({
    rules: {
      email: 'required'
    },
    submitHandler: function(form, event) {
      event.preventDefault();
      var $form = $(form);

      $.ajax({
      type: $form.attr('method'),
      url: $form.attr('action'),
      data: $form.serialize(),
      dataType: 'json',
      beforeSend: function(data) {
        $form.find(':input').prop('disabled', true);
      },
      success: function(data) {
        if (data.success) {
          showMessageBox('success', msg['MSG_SUBSCRIBED']);
          $form.find(':input').prop('disabled', true);
        } else {
          showMessageBox('danger', msg[data.errors.message]);
          $form.find(':input').prop('disabled', false);
        }
      },
      failed: function(data) {
        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        $form.find(':input').prop('disabled', false);
      },
      complete: function(data) {

        // grecaptcha.reset();
      }
      });
    }
  });



  // show/hide password

  $("#showHide").click(function() {
       $(".eye label").toggleClass("stash");
   if ($("#password, #password1").attr("type") == "password") {
   $("#password, #password1").attr("type", "text");
   } else {
   $("#password, #password1").attr("type", "password");
   }
   });



  var validator = $('#subscribe-form').validate({
    ignore: 'input[type="button"],input[type="submit"]',
    rules: {
      username: 'required',
      password1: {
        required: true,
        minlength: 8
      },
      company: 'required',
      cfpiva: 'required',
      i_country_id: 'required',
      tel_prefix: 'required',
      tel: 'required',
      clausola1: 'required'
    },
    errorPlacement: function(error, element) {
      if ($(element).attr('name') === 'clausola1') {
        error.insertAfter($(element).parent());
      } else {
        error.insertAfter(element);
      }
    },
    submitHandler: function(form, event) {
      event.preventDefault();
      var $form = $(form);

        $.ajax({
          type: $form.attr('method'),
          url: $form.attr('action'),
          data: $form.serialize(),
          dataType: 'json',
          beforeSend: function(data) {
            $form.find(':input').prop('disabled', true);
          },
          success: function(data) {
            if (data.success) {
              var rs = $form.attr('redirectsuccess');
              var user = $form.find('input[name=username]').val();
              window.location = $form.attr('redirectsuccess') + "?email=" + user;
            } else {
              showMessageBox('danger', msg[data.errors.message]);
            }
          },
          failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          },
          complete: function(data) {
            $form.find(':input').prop('disabled', false);
          }
        });

    }
  });
});
