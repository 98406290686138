$(document).ready(function() {
  'use strict';

  function submitUserUpdateForm(form, event) {
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize(),
      dataType: 'json',
      beforeSend: function(data) {
        form.find(':input').prop('disabled', true);
      },
      success: function(data) {
        if (data.success) {
        	//TODO customize message
          showMessageBox('success', 'Save successful');
        } else {
         showMessageBox('danger',msg['MSG_GENERIC_ERROR']);
        }
      },
      failed: function(data) {
        showMessageBox('danger',msg['MSG_GENERIC_ERROR']);
      },
      complete: function(data) {
        form.find(':input').prop('disabled', false);
        location.reload();
      }
    });
  }

  $('#user-update-data-form #type-tags input:checkbox').on('click', function() {
    validateTags('#user-update-data-form #type-tags');
  });
  $('#user-update-data-form #gender-tags input:checkbox').on('click', function() {
    validateTags('#user-update-data-form #gender-tags');
  });
  $('#user-update-data-form #category-tags input:checkbox').on('click', function() {
    validateTags('#user-update-data-form #category-tags');
  });
  $('#user-update-data-form #storecount-tags input:checkbox').on('click', function() {
    validateTags('#user-update-data-form #storecount-tags');
  });

  $('#user-update-data-form').validate({
    rules: {
      firstname: 'required',
      lastname: 'required',
      company: 'required',
      notes: 'required',
      cel: 'required',
      cel_prefix: 'required'
    },
    showErrors: function(errorMap, errorList) {
      this.defaultShowErrors();
      // submitHandler is not called if there are error present. we need to
      // check for errors in tags somewhere else.
      validateTags('#type-tags');
      validateTags('#gender-tags');
      validateTags('#category-tags');
      validateTags('#storecount-tags');
    },
    submitHandler: function(form, event) {
      event.preventDefault();

      if (validateTags('#type-tags') && validateTags('#gender-tags') &&
        validateTags('#category-tags') && validateTags('#storecount-tags')) {

        submitUserUpdateForm($(form), event);
      }
    }
  });
  
  $('#user-update-dispatch-form').validate({
    rules: {
      d_addressee: 'required',
      d_country_id: 'required',
      d_street: 'required',
      d_number: 'required',
      d_cap: 'required',
      d_city: 'required',
      d_prov: 'required',
      d_cel_prefix: 'required',
      d_cel: 'required'
    },
    submitHandler: function(form, event) {
      event.preventDefault();
      submitUserUpdateForm($(form), event);
    }
  });

  $('#user-update-invoice-form').validate({
    rules: {
      i_addressee: 'required',
      cfpiva: 'required',
      i_country_id: 'required',
      i_street: 'required',
      i_number: 'required',
      i_cap: 'required',
      i_city: 'required',
      i_prov: 'required'
    },
    submitHandler: function(form, event) {
      event.preventDefault();
      submitUserUpdateForm($(form), event);
    }
  });

	$('#password-change-form').validate({
		rules: {
			password1: 'required',
			password2: {
        		equalTo: '#password1'
      		}
		},
		submitHandler: function(form, event) {
			event.preventDefault();
    		$.ajax({
		        type: $(form).attr('method'),
      			url: $(form).attr('action'),
		        data: $(form).serialize(),
		        dataType: 'json',
		        beforeSend: function(data) {
		          $(form).find(':input').prop('disabled', true);
		        },
		        success: function(data) {
		          if (data.success) {
		            showMessageBox('success', data.data);
		          } else {
		            showMessageBox('danger', data.data);
		          }
		        },
		        complete: function(data) {
		          $(form).find(':input').prop('disabled', false);
		        }
      		});
    	}
	});
});
