$(document).ready(function() {
  'use strict';

	function populateRemoveFilters() {
		$('#filters-dropdown li.active > a').each(function(){
			var id = $(this).data('ref');
			var url = $(this).attr('href');
      $(document.getElementById(id)).attr('href', url);
		});
		$('.current-filters > a').each(function(){
		    if ($(this).attr('href') === '#') {
                $(this).addClass('disabled');
                $(this).children('span.fa').remove();
		    }
		});
		
	}

  function checkPreferredLayout() {
    var layout = localStorage.getItem('catalogLayout');
    if (layout && layout === 'list') {
      $('#list-option').prop('checked', true).trigger('click');
    } else {
      layout = 'grid';
      $('#grid-option').prop('checked', true).trigger('click');
    }

    setCatalogLayout(layout);
  }

  function setCatalogLayout(layout) {
    $('.catalog-layout').toggleClass('catalog-grid', layout === 'grid');
    $('.catalog-layout').toggleClass('catalog-list', layout === 'list');

    localStorage.setItem('catalogLayout', layout);
  }

  function reloadCatalog(url, push) {
  	$('#catalog-container').addClass('catalog-loading');
    $('#catalog-wrapper').load(url + ' #catalog-container', function(){
      if(push) {
      	window.history.pushState(null, document.title, url);
      }
      populateRemoveFilters();
      checkPreferredLayout();
      $('#catalog-container').removeClass('catalog-loading');
      $('#filters-dropdown').show();
      $('.scroll-view').nanoScroller();
      $('.i-number').inputNumber({
        onChange: function(object) {
          $(object).closest('form').find('input[type="submit"]').prop('disabled', false);
        }
      });
    });
  }
  
  function updateCartCount(form) {
    var previous = parseInt($('#cart-button div.count').html());    
  	var addedProducts = 0;
  	form.find('input[type=text]').each(function(){
  		addedProducts += $(this).val() - $(this).data('qtyorig');
  		$(this).data('qtyorig', $(this).val());
  	});
  	var newQuantity = previous + addedProducts;
    $('#cart-button div.count').html(newQuantity);
  }
  
	$(window).on('popstate', function(event) {
  		reloadCatalog(window.location, false);
	});

	$('#catalog-wrapper').on('click', '#filters-dropdown li > a', function(event) {
		event.preventDefault();
		var count = $('#filters-dropdown li.active').length;
		if (count >= 8 && !$(this).parent().hasClass('active')) {
			showMessageBox('warning',general_toomanyfilters);
			$('#filters-dropdown').focus();
		} else {
			var url = $(this).attr('href');
      		reloadCatalog(url, true);
		}
	});

	$('#catalog-wrapper').on('click', '.current-filters a', function(event) {
		event.preventDefault();
		var url = $(this).attr('href');
    	reloadCatalog(url, true);
	});

  $('#catalog-wrapper').on('click', '#filters-dropdown .toggle-subfilter', function(event) {
    event.preventDefault();
    $(this).parent('li').toggleClass('open');
    $(this).closest('.scroll-view').nanoScroller();
  });

  $('#catalog-wrapper').on('click', '#toggle-filters-button', function(event) {
    event.preventDefault();
    $(this).find('.fa').toggleClass('fa-rotate-180x');
    $('#filters-dropdown').slideToggle();
    $('.scroll-view').nanoScroller();
  });

  $('#catalog-wrapper').on('click', '#layout-options input[type="radio"]', function(event) {
    setCatalogLayout(this.id.indexOf('grid') !== -1 ? 'grid' : 'list');
  });

  //Add to cart code
  $('#catalog-wrapper, #product-container').on('submit', '.addtocart-form', function(event) {
    event.preventDefault();
    var form = $(this);
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize(),
      headers: { 'Accept' : 'application/json' },
      success: function(data){
        if(!data.success){
          if (data.code=='MIXED_SALE_CART'){
            showMessageBox('danger',msg['MSG_MIXED_SALE_CART']);
          }else if (data.code=='MIXED_CART'){
            showMessageBox('danger',msg['MSG_MIXED_CART']);
          }else if (data.code=='LOGIN_REQUIRED'){
            location.href = '/' + $('html').attr('lang') + '/login';
          }else{
           showMessageBox('danger',msg['MSG_GENERIC_ERROR']);
          }
        } else {
          showMessageBox('success',msg['MSG_ADDEDTOCART'], {
            content: '<a class="btn" href="/current/cart"><span class="fa fa-shopping-cart fa-spacing" />' +
              buttons_gocheckout + '</a>'
          });
          updateCartCount(form);
  		}
      },
      error: function(xhr, textStatus, errorThrown){
        showMessageBox('danger',msg['MSG_INSUFFICIENT_STOCK']);
      }
    });
  });

  $('.scroll-view').nanoScroller();
	populateRemoveFilters();
  checkPreferredLayout();

});
